import React, { Fragment, useState } from "react";
import Registration from "./Registration";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Step2 from "./Step2";
import BllingData from "./BllingData";

const FormWizard = ({new_version}) => {
  const [steps, setSteps] = useState(1);
  const [formdata, setFormdata] = useState({
    type_product: "tenant",
    new_version: new_version
  });

  return (
    <Fragment> 
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {steps === 1 && <Registration setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}

                {steps === 2 && <BllingData setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}

                {steps === 3 && <Step2 setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}

                <div className="text-center">
                  <span className={`step ${steps > 1 ? "finish" : ""} ${steps === 1 ? "active" : ""}`} />
                  <span className={`step ${steps > 2 ? "finish" : ""} ${steps === 2 ? "active" : ""}`} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default FormWizard;
